import React from 'react'
import { Wrapper } from './updateInfoBnr'

type Props = {
  /** クラス名 */
  className?: string
  /** リンク先 */
  idName?: string
}

/**
 * アップデート情報バナー
 */
const UpdateInfoBnrV3 = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={`function-banner${className}`}>
      <a href={props.idName}>
        <img
          src="/images/official/feature/bnrTopUpdateVer03.png"
          alt="ver3.0 SES案件管理機能"
        />
      </a>
    </Wrapper>
  )
}
export default UpdateInfoBnrV3
