import React from 'react'
import { Wrapper } from './updateInfoBnr'

type Props = {
  /** クラス名 */
  className?: string
  /** リンク先 */
  idName?: string
}

/**
 * アップデート情報バナー
 */
const UpdateInfoBnrV4 = (props: Props): JSX.Element => {
  const { className = '' } = props

  return (
    <Wrapper className={`function-banner${className}`}>
      <a href={props.idName}>
        <img
          src="/images/official/feature/bnrTopUpdateVer04.png"
          alt="ver4.0 経費精算"
        />
      </a>
    </Wrapper>
  )
}
export default UpdateInfoBnrV4
