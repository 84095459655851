import styled from '@emotion/styled'
import React from 'react'
import BreakPoints from '../../styles/breakPoints'
import UpdateInfoBnrV3 from '../atoms/updateInfoBnrV3'
import OfficialNewFunction from '../atoms/officialNewFunction'
import UpdateInfoContentV3 from '../atoms/updateInfoContentV3'
import UpdateInfoContentV4 from '../atoms/updateInfoContentV4'
import UpdateInfoBnrV4 from '../atoms/updateInfoBnrV4'

const Wrapper = styled.div`
  background: #cdebe4;
  position: relative;
  display: flex;
  justify-content: center;
  margin-bottom: 45px;
  padding: 80px 0;

  &:after {
    display: block;
    content: '';
    position: absolute;
    width: 0;
    height: 0;
    border-style: solid;
    border-right: 65px solid transparent;
    border-left: 65px solid transparent;
    border-top: 45px solid #cdebe4;
    border-bottom: 0;
    bottom: -45px;
  }

  .p-new-inner {
    display: flex;
    width: 100%;
    max-width: 1080px;
    margin: 0 auto;
    justify-content: space-between;
    align-items: center;
    position: relative;
  }

  @media (max-width: 990px) {
    padding: 40px 0;
    .p-new-inner {
      flex-direction: column;
    }
  }

  ${BreakPoints.medium} {
  }
`

type Props = {
  /** クラス名 */
  className?: string
}

/**
 * サービスサイト新機能紹介エリア
 * @constructor
 */
const OfficialNewFunctionItem = (props: Props): JSX.Element => {
  const { className = '' } = props
  return (
    <>
      <Wrapper className={className}>
        <div className="p-new-inner anim-fade-up">
          <OfficialNewFunction
            label="経費精算"
            description="電子帳簿保存法に対応した、シンプルで低コストな経費精算をキンクラからそのままご利用できます"
            icon="imgFunction06"
          >
            <UpdateInfoBnrV4 idName="#modal-bnr03" />
          </OfficialNewFunction>
          <OfficialNewFunction
            label="SES案件管理機能"
            description="契約期間や単価情報などSES案件に関する様々なデータをキンクラ上で容易に管理することができます。"
            icon="imgFunction47"
          >
            <UpdateInfoBnrV3 idName="#modal-bnr02" />
          </OfficialNewFunction>
          {/* <OfficialNewFunction */}
          {/*   label="代理申請機能" */}
          {/*   description="代理申請モードに切り替えることで、本人の代わりに各種申請の修正が可能になります。" */}
          {/*   icon="imgFunction48" */}
          {/* > */}
          {/*   <UpdateInfoBnr idName="#modal-bnr01" /> */}
          {/* </OfficialNewFunction> */}
        </div>
      </Wrapper>
      <UpdateInfoContentV4 idName="modal-bnr03" />
      <UpdateInfoContentV3 idName="modal-bnr02" />
      {/* <UpdateInfoContent idName="modal-bnr01" /> */}
    </>
  )
}

export default OfficialNewFunctionItem
