import styled from '@emotion/styled'
import React, { ReactNode } from 'react'

const Wrapper = styled.div`
  width: 90vw;
  max-width: 480px;
  position: relative;
  z-index: 1;
  background: #ffffff;
  padding: 40px 30px 20px;
  border-radius: 10px;
  box-shadow: 5px 5px 0 #ffee81;

  .new-label {
    position: absolute;
    border-radius: 5px 0 5px 0;
    background: #189b6e;
    color: #ffffff;
    font-weight: 600;
    font-size: 1.325em;
    top: 0;
    left: 0;
    padding: 5px;
  }

  .function-inner {
    display: flex;
    margin-bottom: 20px;

    .function-icon {
      margin-right: 10px;

      img {
        max-width: 140px;
        margin-top: 20px;
      }
    }

    .function-detail {
      .name-txt {
        margin: 0 auto 10px;
        font-weight: 600;
        font-size: 1.625em;
        color: #ff9600;
      }

      .description {
        width: 100%;
        white-space: pre-wrap;
      }
    }
  }

  /** 関連バナーclassName */

  .function-banner {
    width: 90%;
    margin: 0 auto;

    a {
      transition: 0.2s;

      img {
        filter: unset;
      }

      &:hover {
        transform: scale(1);
        opacity: 0.6;
      }
    }
  }

  @media (max-width: 990px) {
    margin: 20px auto;
    position: relative;
    z-index: 1;
    background: #ffffff;
    padding: 40px 30px 20px;
    border-radius: 10px;
    box-shadow: 5px 5px 0 #ffee81;

    .new-label {
      position: absolute;
      border-radius: 5px 0 5px 0;
      background: #189b6e;
      color: #ffffff;
      font-weight: 600;
      font-size: 1.325em;
      top: 0;
      left: 0;
      padding: 5px;
    }

    .function-inner {
      display: flex;
      margin-bottom: 20px;

      .function-icon {
        margin-right: 10px;

        img {
          max-width: 140px;
          margin-top: 20px;
        }
      }

      .function-detail {
        .name-txt {
          margin: 0 auto 10px;
          font-weight: 600;
          font-size: 1.625em;
          color: #ff9600;
        }

        .description {
          width: 100%;
          white-space: pre-wrap;
        }
      }
    }
  }
`
type Props = {
  /** タイトル */
  label: string
  /** 画像ファイル名 */
  icon: string
  /** 内容（画像） */
  children: ReactNode
  /** 補足テキスト */
  description: string
}

/**
 * アップデート情報表示コンテンツ（ポップアップ表示）
 */
const OfficialNewFunction = (props: Props): JSX.Element => (
  <Wrapper>
    <p className="new-label">新機能</p>
    <div className="function-inner">
      <div className="function-icon">
        {/** 機能アイコン */}
        <img
          src={`/images/official/function/${props.icon}.png`}
          alt={props.label}
        />
      </div>
      <div className="function-detail">
        {/** 機能名 */}
        <div className="name-txt">{props.label}</div>
        {/** 機能説明 */}
        <p className="description">{props.description}</p>
      </div>
    </div>
    {/** 関連バナー */}
    {props.children}
  </Wrapper>
)
export default OfficialNewFunction
